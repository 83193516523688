.bowl {
  width: 300px;
  height: 300px;
  margin: auto;
  background-color: rgba(225, 255, 255, 0.1);
  border-radius: 50%;
  border: 4px solid black;
  transform-origin: bottom center;
  animation: animateBowl 5s linear infinite;
}

.bowlTop {
  position: relative;
  transform-origin: top center;
  height: 32px;
  width: 120px;
  background-color: #fafafa;
  border: 4px solid black;
  border-radius: 50%;
  top: -10px;
  left: 86px;
}

.water {
  position: relative;
  top: 40%;
  left: 1%;
  background-color: rgb(129, 209, 247);
  border-bottom-right-radius: 158px;
  border-bottom-left-radius: 158px;
  transform-origin: top center;
  animation: animateWater 5s linear infinite;
  height: 140px;
  width: 286px;
}

.waterTop {
  position: relative;
  top: -10px;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  background-color: #1fa4e0;
}

.shadow {
  position: relative;
  top: -15px;
  width: 300px;
  height: 30px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  border-radius: 50%;
}

img.fish {
  width: 100px;
  animation: animateFish 5s linear infinite;
}

@keyframes animateBowl {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes animateWater {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes animateFish {
  0% {
    transform: translate(50px, 0) rotateY(180deg) rotateZ(-35deg);
  }
  50% {
    transform: translate(150px, 0) rotateY(180deg) rotateZ(-35deg);
  }
  51% {
    transform: translate(150px, 0) rotateY(0deg) rotateZ(-35deg);
  }
  100% {
    transform: translate(50px, 0) rotateY(0deg) rotateZ(-35deg);
  }
}
